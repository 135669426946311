// extracted by mini-css-extract-plugin
export var wrapper = "contact-module--wrapper--3_uMw";
export var inquiry = "contact-module--inquiry--1c8lr";
export var form = "contact-module--form--2oJOI";
export var notice = "contact-module--notice--1KDee";
export var field = "contact-module--field--6o-G7";
export var fieldRos = "contact-module--field-ros--2np_U";
export var fieldVi = "contact-module--field-vi--3ym63";
export var message = "contact-module--message--6eBT-";
export var buttonBox = "contact-module--button-box--2kB7B";
export var content = "contact-module--content---5zRy";
export var before = "contact-module--before--o139S";
export var after = "contact-module--after--_UniJ";
export var contact = "contact-module--contact--2VJCr";
export var social = "contact-module--social--2Qjit";